<template>
  <div class="flex justify-center items-center mt-4">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          Crear Respuesta
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4" style="width: 100% !important;">
              <dropdown
                placeholder="Tipo de respuesta"
                :options="tiposProcesos"
                option-label="name"
                option-value="id"
                @change="onChangeTipo"
                v-model="model.tipo"
                class="w-full"
              />
              <MessageError :text="errors.tipo"/>
            </div>
            <div class="mb-4">
              <dropdown
                placeholder="Seleccione una opcion"
                :options="dataDropdown"
                option-label="nombre"
                option-value="id"
                v-model="model.base"
                class="w-full"
              >
                <template #option="slotProps">
                  <div class="flex">
                    <div class="w-5/12" v-if="slotProps.option.titleUser">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.titleUser }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.user }}
                      </p>
                    </div>
                    <div class="w-2/12">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.titleId }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.id }}
                      </p>
                    </div>
                    <div class="w-5/12">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.title }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.nombre.slice(0, 30) }}...
                      </p>
                    </div>
                  </div>
                </template>
              </dropdown>
              <MessageError :text="errors.base"/>
            </div>
            <div class="mb-4">
              <InputText
                v-model="model.observacion"
                placeholder="Observaciones"
                class="w-full"/>
              <MessageError :text="errors.observacion"/>
            </div>
            <div>
              <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                Agregar nuevo soporte
              </button>
            </div>
          </div>
        </div>

        <div class="px-2 w-full mb-5">
          <div style="max-height: 150px; overflow-y: auto;">
            <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
              <div class="flex mt-2">
                <button @click="verPdf(item.urlPath)">
                  <i class="pi pi-file-pdf mr-2 text-black cursor-pointer"></i>
                </button>
                <p>{{ item.fileName }}</p>
              </div>
              <div class="">
                <button @click="quitarPdf(key)"><i class="pi pi-trash mr-2 text-red-600"></i></button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
            <div>
              <div>
                <label class="text-sm" for="titulo">Titulo</label>
                <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                       class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              </div>
              <div class="my-2">
                <label class="text-sm">Adjunte el documento</label>
                <div class="mt-2">
                  <FilePond ref="pond"/>
                </div>
              </div>
            </div>
            <template class="text-center" #footer>
              <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
            </template>
          </Dialog>
        </div>

        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <Button label="Crear"
                  class="bg-blue-900"
                  style="width: 244px;"
                  type="button"
                  @click="handleNext"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import storeTiposProcesos from '@/apps/pharmasan/juridica/procesosJuridicos/store/tiposProcesos.store'
import storeRespuestas from '@/apps/pharmasan/juridica/procesosJuridicos/store/respuestas.store'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import TutelaService from '@/apps/pharmasan/juridica/procesosJuridicos/services/tutela.service'
import IncidenciasService from '@/apps/pharmasan/juridica/procesosJuridicos/services/incidencias.service'
import FallosService from '@/apps/pharmasan/juridica/procesosJuridicos/services/fallos.service'
import RespuestasService from '@/apps/pharmasan/juridica/procesosJuridicos/services/respuestas.service'
import DerechosPeticionService from '../../../../services/derechosPeticion.service'
import ProcesosAdministrativosService from '../../../../services/procesosAdministrativos.service'
import DesacatosService from '../../../../services/desacatos.service'
import { useRoute, useRouter } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import derechosPeticionStore from '../../../../store/derechosPeticion.store'

export default {
  name: 'crearRespuestas',
  components: {
    FilePond
  },
  setup () {
    const _TutelaService = new TutelaService()
    const _IncidenciasService = new IncidenciasService()
    const _FallosService = new FallosService()
    const _RespuestasService = new RespuestasService()
    const _ProcesosAdministrativosService = new ProcesosAdministrativosService()
    const _DerechosPeticionService = new DerechosPeticionService()
    const _DesacatosService = new DesacatosService()
    const pond = ref()
    const router = useRouter()
    const route = useRoute()
    const tiposProcesos = ref([])
    const dataDropdown = ref([])
    const displayMaximizable = ref(false)
    const adjuntos = ref([])
    const _PdfService = new PdfService()
    const title = ref('')

    /** state **/
    const schema = yup.object({
      tipo: yup.number().nullable().required('El campo es requerido').label(''),
      base: yup.number().nullable().required('El campo es requerido').label(''),
      observacion: yup.string().nullable().required('El campo es requerido').label(''),
      provisionalidad: yup.bool().nullable()
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo')
    useField('base')
    useField('observacion')
    useField('provisionalidad', null, { initialValue: false })
    /** data **/

    /** computed **/
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const derechos = computed(
      () => derechosPeticionStore.getters._derechos
    )

    /** methodos **/
    const handleNext = handleSubmit(async (values) => {
      if (!values) return console.error('Error => validation')
      storeRespuestas.dispatch('createRespuesta', {
        ...values,
        paths: adjuntos.value
      }).then(() => {
        router.go(-1)
      })
    })

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const onChangeTipo = ({ value }) => {
      if (value === 1) {
        _TutelaService.getPaginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observaciones,
              titleId: 'ID:',
              id: e.id,
              titleUser: 'Nombre:',
              user: e.accionanteFullname
            }
          })
        })
      } else if (value === 2) {
        _IncidenciasService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 3) {
        _FallosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 4) {
        _RespuestasService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 5) {
        _DerechosPeticionService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              titleUser: 'Persona:',
              user: e.personaName,
              titleId: 'ID:',
              id: e.id,
              title: 'Asunto:',
              nombre: e.asunto
            }
          })
        })
      } else if (value === 6) {
        _DesacatosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 10) {
        _ProcesosAdministrativosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              titleUser: 'Persona:',
              user: e.personaName,
              titleId: 'ID:',
              id: e.id,
              title: 'Asunto:',
              nombre: e.asunto
            }
          })
        })
      }
    }

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    /** hooks **/
    onMounted(() => {
      storeTiposProcesos.dispatch('getPaginate').then(({ data }) => {
        tiposProcesos.value = data.rows
      })
      if (route.query.tipo) {
        model.tipo = parseInt(route.query.tipo)
        if (parseInt(route.query.tipo) === 1) {
          _TutelaService.getPaginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                title: 'Observaciones:',
                nombre: e.observaciones,
                titleId: 'ID:',
                id: e.id,
                titleUser: 'Nombre:',
                user: e.accionanteFullname
              }
            })
          })
        } else if (parseInt(route.query.tipo) === 2) {
          _IncidenciasService.paginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                titleUser: 'Tipo Incidente:',
                user: e.tipoincidencia === 1 ? 'Incidente tipo Apertura Formal' : 'Incidente tipo requerimiento',
                titleId: 'ID:',
                id: e.id,
                title: 'Observacion:',
                nombre: e.observacion
              }
            })
          })
        } else if (parseInt(route.query.tipo) === 5) {
          _DerechosPeticionService.paginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                titleUser: 'Persona:',
                user: e.personaName,
                titleId: 'ID:',
                id: e.id,
                title: 'Asunto:',
                nombre: e.asunto
              }
            })
          })
        } else if (parseInt(route.query.tipo) === 10) {
          _ProcesosAdministrativosService.paginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                titleUser: 'Persona:',
                user: e.personaName,
                titleId: 'ID:',
                id: e.id,
                title: 'Asunto:',
                nombre: e.asunto
              }
            })
          })
        }
        model.base = parseInt(route.query.base)
        model.provisionalidad = route.query.provisionalidad
      }

      if (route.params.id) {
        storeRespuestas.dispatch('findRespuesta', route.params.id).then((data) => {
          if (data.tipo === 1) {
            _TutelaService.getPaginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observaciones,
                  titleId: 'ID:',
                  id: e.id,
                  titleUser: 'Nombre:',
                  user: e.accionanteFullname
                }
              })
            })
          } else if (data.tipo === 2) {
            _IncidenciasService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 3) {
            _FallosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 4) {
            _RespuestasService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 5) {
            _DerechosPeticionService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  titleUser: 'Persona:',
                  user: e.personaName,
                  titleId: 'ID:',
                  id: e.id,
                  title: 'Asunto:',
                  nombre: e.asunto
                }
              })
            })
          } else if (data.tipo === 6) {
            _DesacatosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 10) {
            _ProcesosAdministrativosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  titleUser: 'Persona:',
                  user: e.personaName,
                  titleId: 'ID:',
                  id: e.id,
                  title: 'Asunto:',
                  nombre: e.asunto
                }
              })
            })
          }
          model.tipo = data.tipo
          model.base = data.base
          model.observacion = data.observacion
          adjuntos.value = data.paths
        })
      }
    })

    const quitarPdf = (key) => {
      adjuntos.value.splice(key, 1)
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    return {
      errors,
      model,
      handleNext,
      tiposProcesos,
      dataDropdown,
      pond,
      onChangeTipo,
      openMaximizable,
      displayMaximizable,
      adjuntos,
      verPdf,
      quitarPdf,
      closeMaximizable,
      title,
      derechos
    }
  }
}
</script>

<style lang="scss">
.p-message .p-message-wrapper{
  padding: 0.5rem 1.5rem;
}
</style>
