import http from '../../../../../libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class ProcesosAdministrativosService {
  paginate (params) {
    return http.get(`${baseUrl}/procesos-administrativos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/procesos-administrativos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/procesos-administrativos/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/procesos-administrativos/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
